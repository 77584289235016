import { useEffect, useState } from "react";

export const useCountdownTimer = (targetDay, targetHour, targetMinute) => {
    const calculateTimeLeft = () => {
        const now = new Date();
        const targetDate = new Date(now.getFullYear(), now.getMonth(), targetDay, targetHour, targetMinute, 0);

        if (now > targetDate) {
            return {
                hours: "00",
                minutes: "00",
                seconds: "00",
            };
        }

        const difference = targetDate - now;
        return {
            hours: Math.floor(difference / (1000 * 60 * 60)),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return timeLeft;
};