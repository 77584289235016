import React from 'react'

export default function ReminderModal({ countTime, onRmModal, setRmOnModal }) {
    return (
        <>
            <div style={{ display: onRmModal == true ? 'block' : 'none' }} className="modal modal-remainder">
                <div className="modal-dialog modal-dialog-centered rm-w-set modal-dialog-scrollable">
                    <div className="modal-content rm-b-modal position-relative  ">
                        <button onClick={() => { setRmOnModal(false) }} className="md-close-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M12.6663 3.83322L3.33301 13.1666M3.33301 3.83322L12.6663 13.1666" stroke="#191722" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                        <div className='rm-align'>
                            <div className='text-center'>
                                <h2 className='rm-main-title mb-10'>Faith Farming land</h2>
                                {/* <h2 className='rm-main-title mb-10'>Faith Meta Farming Begins In</h2> */}
                                <p className="md-pera  op-70">Gear up to grow, trade, and profit!</p>
                            </div>
                            <div>
                                <div className='rm-time-line mb-10'>
                                    {/* <div className='text-center flex-001'>
                                        <h4 className='rm-time-h m-0'>{countTime?.hours}</h4>
                                        <p className='rm-time-h-pera op-70'>Hours</p>
                                    </div>
                                    <div>
                                        <div className='mb-1 rm-jem'></div>
                                        <div className='rm-jem'></div>
                                    </div>
                                    <div className='text-center flex-001'>
                                        <h4 className='rm-time-h m-0'>{countTime?.minutes}</h4>
                                        <p className='rm-time-h-pera op-70'>Minutes</p>
                                    </div>
                                    <div>
                                        <div className='mb-1 rm-jem'></div>
                                        <div className='rm-jem'></div>
                                    </div>
                                    <div className='text-center flex-001'>
                                        <h4 className='rm-time-h m-0'>{countTime?.seconds}</h4>
                                        <p className='rm-time-h-pera op-70'>Seconds</p>
                                    </div> */}
                                    <h4 className='rm-time-h m-0'>Phase 2: Live Now!</h4>

                                </div>
                                <button className="md-btn-align w-100">
                                    <a href='https://metahuman.faithmeta.land/' className='text-white' target='_blank' >
                                        Participate now!!
                                    </a>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
