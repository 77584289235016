
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Countdown from "react-countdown";

export default function SeedPopup() {

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} size="lg" centered onHide={handleClose} className="presale_card">
        <Modal.Body className="p-0 position-relative">
          <button className="close" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 4L12.5 12.5M4 21L12.5 12.5M12.5 12.5L21 21M12.5 12.5L4 4"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <a href="https://portal.faithprotocol.io/buy-token" target="_blank">
            <img
              src="assets/image/popup/faith-presale.jpg"
              alt="faith protocol presale"
              className="w-100"
            />
            {/* <h5 className="presale_title mt-2">
              starts at <br className="d-md-block d-none" />
              13th September 2024
            </h5>
            <div className="presale_border" />
            <div className="text-center">
              <Countdown
                className="presale_timer countdown-timer"
                date={new Date("2024-09-13T05:30:00")}
              />
            </div> */}
          </a>
        </Modal.Body>
      </Modal>
    </>
  );
}