import { useState } from "react";
import { Modal } from "react-bootstrap";

export default function StickyPopup() {
  const [showModal1, setShowModal1] = useState(true);
  const [showModal2, setShowModal2] = useState(true);

  return (
    <>
      {/* First Modal */}
      <Modal show={showModal1} size="lg" centered onHide={() => setShowModal1(false)} className="presale-footer presale-footer-one  ">
        <Modal.Body className="p-0 position-relative">
          <button className="close" onClick={() => setShowModal1(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none">
              <path d="M21 4L12.5 12.5M4 21L12.5 12.5M12.5 12.5L21 21M12.5 12.5L4 4" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <a href="https://faithfarming.com/" target="_blank" className="d-inline-block">
            <img src="assets/image/popup/faith-farming.png" alt="faith farming" className="w-100" />
          </a>
        </Modal.Body>
      </Modal>

      {/* Second Modal */}
      <Modal show={showModal2} size="lg" centered onHide={() => setShowModal2(false)} className="presale-footer">
        <Modal.Body className="p-0 position-relative">
          <button className="close" onClick={() => setShowModal2(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none">
              <path d="M21 4L12.5 12.5M4 21L12.5 12.5M12.5 12.5L21 21M12.5 12.5L4 4" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <a href="https://portal.faithprotocol.io/buy-token" target="_blank" className="d-inline-block">
            <img src="assets/image/popup/faith-pre-sale.png" alt="faith-pre-sale" className="w-100" />
          </a>
        </Modal.Body>
      </Modal>
    </>
  );
}